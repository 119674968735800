/* External Styles for contact-widget */
.contact-widget {
    background-color: #1A384C;
    padding: 20px;
    border-radius: 10px;
  }
  
  .contact-widget h2 {
    color: white;
    text-decoration: none;
    margin-bottom: 20px;
  }
  
  /* External Styles for the Contact Form */
  .contact-widget form {
    margin-top: 20px;
    text-align: center; /* Center the content horizontally */
  }
  
  .contact-widget .form-group {
    margin-bottom: 15px;
  }

  .contact-widget label {
    color: white;
    display: block;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .contact-widget input,
  .contact-widget textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contact-widget button {
    margin-top: 20px;
    background-color: #FF4B19;
    color: white;
    border: none;
    padding: 12px 60px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-widget button:hover {
    background-color: #FF4B19;
  }

  /* External Styles for detail-widget */
.detail-widget {
    background-color: #1A384C;
    padding: 20px;
    border-radius: 10px;
  }
  
  .detail-widget h3 {
    color: white;
  }
  
  /* External Styles for contact-info */
  .contact-info {
    margin-top: 20px;
  }
  
  /* External Styles for info-item */
  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  /* External Styles for icon */
  .info-item i {
    margin-right: 25px; /* Adjust the spacing between icon and text */
    font-size: 40px; /* Adjust the font size of the icon */
    color: #fff; /* Icon color */
  }
  
  /* External Styles for text */
  .info-item span {
    color: white;
    font-weight: bold;
    font-size: 20px; /* Adjust the font size of the text */
  }

  .hotline-text{
    font-size: 24px;
  }
  
  
  
/*--------------------------------------------------------------
2. header
--------------------------------------------------------------*/

/*-- 2.1 topbar --*/

.topbar {
    background: $dark-gray2;
    padding: 0 130px;

    @media (max-width: 1600px) {
        padding: 0 30px;
    }
    @media (max-width: 1500px) {
        padding: 0 10px;
    }
}

@media (max-width: 991px) {
    .topbar {
        text-align: center;
        padding: 0;
    }
}

.topbar {
    font-size: 16px;
    color: #e5e3e3;
    margin: 0;
}

.topbar ul {
    overflow: hidden;
    list-style: none;
    float: right;
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .topbar ul {
        float: none;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.topbar ul li {
    font-size: 16px;
    float: left;
    padding: 10px 10px;
    color: #e5e3e3;
    line-height: 1em;

    @media (max-width: 1399px) {
        font-size: 14px;
    }
    @media (max-width: 991px) {
        padding: 15px 10px;
    }
}

.topbar .contact-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 991px) {
        justify-content: center;
    }

    @media (max-width: 767px) {
        flex-wrap: wrap;
    }

    li {
        position: relative;
    }

    ul {
        &.social {
            margin-left: 20px;

            @media (max-width: 475px) {
                margin-left: 0;
            }

            li {
                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border: 1px solid transparentize($white, 0.8);
                    border-radius: 50%;
                    text-align: center;

                    &:hover {
                        background: $theme-primary-color;
                        color: $white;
                    }
                }
            }
        }
    }
}
.topbar .contact-info ul li a {
    color: #e4e4e4;
}

.topbar .contact-info ul li a:hover {
    color: $theme-primary-color;
}

.topbar ul li:first-child {
    padding-left: 0;
}

.topbar .contact-intro ul {
    float: left;
    margin-bottom: 0;

    @media (max-width: 991px) {
        float: none;
    }
}

@media (max-width: 1200px) {
    .topbar ul li {
        font-size: 12px;
        padding: 10px 4px;
    }
}

@media (max-width: 991px) {
    .topbar ul li {
        font-size: 13px;
    }
    .topbar {
        padding: 10px 0;
    }
}

@media (max-width: 767px) {
    .topbar ul li {
        float: left;
        border: none;
        padding: 8px 6px;
        font-size: 13px;
    }
}

@media (max-width: 590px) {
    .topbar ul li {
        float: none;
    }

    .topbar .contact-info ul li {
        float: left;
    }
    .topbar ul {
        flex-wrap: wrap;
    }
}

.topbar .theme-btn-s2 {
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -3px;
    color: #fff !important;
}

.topbar ul .fi:before {
    position: relative;
    top: 1px;
    display: inline-block;
    padding-right: 5px;
}

.topbar ul .fi:before {
    font-size: 15px;
    font-size: 1rem;
    color: #fff;
}

.topbar-none {
    .topbar {
        display: none;
    }
}

/* 2.2 navigation */

.wpo-site-header {
    /* navigation open and close btn hide for width screen */
    /* style for navigation less than 992px */
    /*navbar collaps less then 992px*/
    /*** cart-search-contact ***/
    background-color: #fff;
}

.wpo-site-header .navigation {
    background-color: #fff;
    margin-bottom: 0;
    padding: 0;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.sticky-header {
    width: 100%;
    position: fixed;
    left: 0;
    top: -400px;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    -webkit-box-shadow: 0 -5px 15px #086ad84a;
    box-shadow: 0 -5px 15px #086ad84a;
}

.sticky-on {
    opacity: 1;
    top: 0;
    visibility: visible;
}

.wpo-site-header .navigation > .container {
    position: relative;
}

@media (min-width: 991px) {
    .wpo-site-header {
        padding: 0 130px;
    }
}

.wpo-site-header {
    .row {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .wpo-site-header .navigation {
        padding: 20px 0;
    }
}

.wpo-site-header #navbar {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

.wpo-site-header #navbar > ul li a:hover,
.wpo-site-header #navbar > ul li a:focus {
    text-decoration: none;
    color: $theme-primary-color;
}

@media screen and (min-width: 992px) {
    .wpo-site-header #navbar {
        /*** hover effect ***/
        justify-content: center;
    }
    .wpo-site-header #navbar li {
        position: relative;
    }

    .wpo-site-header #navbar > ul > li > a:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 4px;
        background: $theme-primary-color;
        content: '';
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        border-radius: 3px;
    }
    .wpo-site-header #navbar > ul > li > a:hover:before {
        opacity: 1;
        visibility: visible;
    }
    .wpo-site-header #navbar > ul > li > a.active {
        color: $theme-primary-color;
    }

    .wpo-site-header
        #navbar
        > ul
        > li
        > .sub-menu
        > .menu-item-has-children
        > a {
        position: relative;
    }
    .wpo-site-header
        #navbar
        > ul
        > li
        > .sub-menu
        > .menu-item-has-children
        > a:before {
        font-family: 'themify';
        content: '\e649';
        font-size: 11px;
        font-size: 0.73333rem;
        position: absolute;
        right: 15px;
        top: 58%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .wpo-site-header #navbar > ul > li:hover > .sub-menu {
        top: 100%;
        visibility: visible;
        opacity: 1;
    }
    .wpo-site-header #navbar .sub-menu > li:hover > .sub-menu {
        left: 100%;
        visibility: visible;
        opacity: 1;
    }
}
.wpo-site-header .container-fluid {
    padding-right: 0;
}

@media (max-width: 991px) {
    .wpo-site-header {
        /* class for show hide navigation */
    }
    .wpo-site-header .container {
        width: 100%;
        max-width: 100%;
    }

    .wpo-site-header .header-right {
        right: -10px;
    }
    .wpo-site-header .navbar-header button span {
        background-color: #fff;
        width: 20px;
        display: block;
        height: 2px;
        margin-bottom: 5px;
    }
    .wpo-site-header .navbar-header button span:last-child {
        margin: 0;
    }
}
.wpo-site-header #navbar {
    ul {
        list-style: none;
    }
}

@media (max-width: 991px) and (max-width: 991px) {
    .wpo-site-header #navbar {
        top: 0;
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 991px) {
    .wpo-site-header #navbar > ul {
        z-index: 101;
    }
    .wpo-site-header #navbar ul a {
        color: #000;
    }
    .wpo-site-header #navbar ul a:hover,
    .wpo-site-header #navbar ul li.current a {
        color: $theme-primary-color;
    }
    .wpo-site-header #navbar .close-navbar .ti-close {
        position: relative;
        top: 1px;
    }

    .wpo-site-header .navbar-toggler .first-angle,
    .wpo-site-header .navbar-toggler .last-angle {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -ms-transition: all 0.3s;
        transition: all 0.3s;
    }
    .wpo-site-header .x-close .middle-angle {
        opacity: 0;
    }
    .wpo-site-header .x-close .first-angle {
        position: absolute;
        -webkit-transform: rotate(-44deg);
        top: 16px;
        left: 10px;
    }
    .wpo-site-header .x-close .last-angle {
        -webkit-transform: rotate(44deg);
        position: absolute;
        top: 16px;
        right: 10px;
    }
}

//TODO: remove after logo designed
.wpo-site-header .navbar-header .navbar-brand {
    display: flex;
}
.wpo-site-header .navbar-header .navbar-brand h2 {
    color: #fff;
    filter: drop-shadow(2px 4px 6px black);
    &.header-3 {
        color: unset;
        filter: drop-shadow(2px 4px 6px white);
    }
    span {
        color: #ff4a17;
        &.big-letter {
            font-size: 50px;
        }
    }
}

@media (max-width: 767px) {
    .wpo-site-header .navbar-header .navbar-brand {
        font-size: 24px;
    }
    .wpo-site-header .navbar-header .navbar-brand h2 {
        //TODO: remove after logo designed
        font-size: 20px;
        span {
            &.big-letter {
                font-size: 30px;
            }
        }
    }
    .wpo-site-header #navbar .navbar-nav {
        margin: 0;
    }
}

@media (max-width: 991px) {
    .wpo-site-header .navbar-collapse.collapse {
        display: none;
    }
    .wpo-site-header .navbar-collapse.collapse.in {
        display: block;
    }
    .wpo-site-header .navbar-header .collapse,
    .wpo-site-header .navbar-toggle {
        display: block;
    }
    .wpo-site-header .navbar-header {
        float: none;
        position: relative;
        z-index: 99;
        text-align: center;
    }
    .wpo-site-header .navbar-right {
        float: none;
    }
    .wpo-site-header .navbar-nav {
        float: none;
    }
    .wpo-site-header .navbar-nav > li {
        float: none;
    }
}

.wpo-site-header .header-right {
    display: flex;
    justify-content: flex-end;
    z-index: 991;
    position: relative;
    align-items: center;
}
.wpo-site-header .header-right button {
    padding: 0;
    border: 0;
    outline: 0;
}

.wpo-site-header .header-right > div {
    float: left;
    position: relative;
    margin-right: 8px;
}

.wpo-site-header .header-right {
    .close-form {
        .theme-btn {
            padding: 10px 25px;
            padding-right: 45px;

            &:before {
                right: 25px;
            }

            @media (max-width: 1800px) {
                padding: 10px 20px;
                font-size: 15px;

                &:before {
                    display: none;
                }
            }
            @media (max-width: 1300px) {
                padding: 10px 15px;
            }
        }
    }
}

@media (max-width: 450px) {
    .wpo-site-header .header-right > div {
        margin-right: 10px;
    }
    .wpo-site-header .header-right {
        right: -30px;
    }
}

.wpo-site-header .header-right > div:last-child {
    margin: 0 0 0 10px;
    position: relative;

    @media (max-width: 767px) {
        margin: 3px 0 0 10px;
    }
}

.wpo-site-header .header-right .header-search-form-wrapper {
    position: relative;
}

.wpo-site-header .header-right .header-search-form-wrapper .fi:before {
    font-size: 18px;
}

.wpo-site-header .header-right .cart-toggle-btn {
    @media (max-width: 450px) {
        display: none;
    }
}
.wpo-site-header .header-right .search-toggle-btn,
.wpo-site-header .header-right .cart-toggle-btn {
    background-color: rgba(255, 255, 255, 0.05);
    line-height: 0.66em;
    color: #232f4b;
    width: 50px;
    height: 50px;
    border: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;

    @media (max-width: 1200px) {
        width: 50px;
        height: 50px;
    }
    @media (max-width: 991px) {
        width: 40px;
        height: 40px;
    }

    .fi {
        &:before {
            color: $white;
            @media (max-width: 991px) {
                font-size: 16px;
            }
        }
    }

    .ti-close {
        display: block;
        line-height: 20px;
        position: relative;
        top: -2px;
    }
}

/*** cart-search-contact ***/
.cart-search-contact {
    button {
        background: transparent;
        padding: 0;
        border: 0;
        outline: 0;
    }

    .fi {
        font-size: 30px;
        font-size: calc-rem-value(30);
        color: $dark-gray;
        line-height: unset;
    }

    > div {
        float: left;
        position: relative;
    }

    > div + div {
        margin-left: 12px;
    }

    .header-search-form {
        position: absolute;
        width: 350px;
        right: 0;
        top: 75px;
        transform: scale(0);
        opacity: 0;
        visibility: hidden;
        background: $white;
        padding: 30px;
        border-radius: 6px;
        @include transition-time(0.5s);
        box-shadow: -2px 18px 40px -9px rgba(153, 153, 153, 1);

        @include media-query(991px) {
            top: 65px;
        }

        @include media-query(767px) {
            right: 15px;
        }
        @include media-query(575px) {
            width: 260px;
            padding: 15px;
        }
    }

    .header-search-form-wrapper .fi:before {
        font-size: 18px;
        font-size: calc-rem-value(18);
    }

    form div {
        position: relative;

        button {
            position: absolute;
            right: 15px;
            top: 50%;
            @include translatingY();

            .fi {
                &:before {
                    font-size: 18px !important;
                }
            }
        }
    }

    input {
        width: 100%;
        height: 50px;
        padding: 6px 20px;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        border: 1px solid $border-color;

        &:focus {
            box-shadow: none;
            border-color: $theme-primary-color;
        }
    }
}

.wpo-site-header .header-right .header-search-content-toggle {
    opacity: 1;
    visibility: visible;
    right: 0;
    transform: scale(1);
}

.mini-cart .cart-count {
    background: $theme-primary-color;
    width: 19px;
    height: 19px;
    font-size: 10px;
    font-size: calc-rem-value(8);
    line-height: 17px;
    color: white;
    position: absolute;
    top: -2px;
    right: -5px;
    border-radius: 50%;
    font-weight: 600;
}

.mini-cart-content {
    background: $white;
    width: 320px;
    height: 100%;
    z-index: 100;
    position: fixed;
    right: -320px;
    top: 0;
    opacity: 0;
    visibility: hidden;
    @include transition-time(0.5s);
    box-shadow: -2px 18px 40px -9px rgba(153, 153, 153, 1);

    @media (max-width: 575px) {
        width: 290px;
    }

    .mini-cart-close {
        display: block;
        width: 40px;
        height: 40px;
        background-color: rgb(51, 51, 51);
        color: rgb(255, 255, 255);
        font-size: 0px;
        text-align: center;
        overflow: hidden;
        position: relative;
        float: right;
        line-height: 54px;
        border-radius: 3px;
        right: 10px;
        top: 20px;

        i {
            font-size: 15px;
        }
    }

    p {
        font-size: 15px;
        font-size: calc-rem-value(15);
        font-weight: 500;
        color: $heading-color;
        margin: 0;
    }

    .mini-cart-items {
        padding: 100px 20px 25px;
    }

    .mini-cart-item {
        padding-top: 15px;
        margin-top: 15px;
        border-top: 1px solid #efefef;
    }

    .mini-cart-item:first-child {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
    }

    .mini-cart-item-image {
        width: 50px;
        height: 50px;
        border: 2px solid #f3f3f3;
        float: left;
        margin-right: 15px;
    }

    .mini-cart-item-image a,
    .mini-cart-item-image img {
        display: block;
        width: 46px;
        height: 46px;
    }

    .mini-cart-item-des {
        position: relative;
        overflow: hidden;
    }

    .mini-cart-item-des a {
        font-size: 14px;
        font-size: calc-rem-value(14);
        font-weight: 600;
        text-align: left;
        color: $heading-color;
    }

    .mini-cart-item-des a:hover {
        color: $theme-primary-color;
    }

    .mini-cart-item-des .mini-cart-item-price {
        font-size: 13px;
        font-size: calc-rem-value(13);
        color: #888;
        display: block;
        margin-top: 3px;
    }

    .mini-cart-item-des .mini-cart-item-quantity {
        font-size: 12px;
        font-size: calc-rem-value(12);
        color: #444;
        display: block;
        position: absolute;
        right: 0;
        top: 6px;
    }
    .visible-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        height: 300px;
        opacity: 0.02;
        z-index: -1;
    }

    .mini-cart-action {
        padding: 20px 0 30px;
        text-align: center;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);

        .theme-btn-s2 {
            float: right;
        }

        .mini-btn {
            width: 240px;
        }
    }

    .mini-cart-action .mini-checkout-price {
        font-size: 16px;
        font-size: calc-rem-value(16);
        font-weight: 600;
        color: $heading-color;
        display: block;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            color: #c79652;
            font-size: 20px;
        }
    }
}

.mini-cart-content-toggle,
.mini-wislist-content-toggle {
    opacity: 1;
    visibility: visible;
    right: 0;
}

@media (max-width: 991px) {
    .page-wrapper {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -ms-transition: all 0.3s;
        transition: all 0.3s;
    }
    .body-overlay:before {
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.9);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -ms-transition: all 0.3s;
        transition: all 0.3s;
    }
}

.header-search-area {
    background: #fff;
    width: 100%;
    padding: 100px 0;
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
}

.header-search-area h3 {
    font-size: 45px;
    margin: 0 0 1em;
}

.header-search-area form {
    position: relative;
}

.header-search-area input {
    height: 50px;
    font-size: 18px;
    font-size: 1.2rem;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #d0d0d0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.header-search-area input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: $theme-primary-color;
}

.header-search-area form button {
    background: transparent;
    border: 0;
    font-size: 20px;
    font-size: 1.33333rem;
    position: absolute;
    right: 0;
    bottom: 10px;
}

.header-search-area form button:focus {
    border: none;
    outline: none;
}

.header-search-area .close-form {
    position: absolute;
    right: 35px;
    top: 35px;
    bottom: auto;
    padding-bottom: 5px;
    border-bottom: 1px solid;
}

.header-search-area .close-form button {
    background: transparent;
    border: 0;
    outline: 0;
    font-size: 13px;
    font-size: 0.86667rem;
    text-transform: uppercase;
    color: #000;
    letter-spacing: 2px;
    z-index: 99;
}

.header-search-area .close-form button:focus {
    border: 0;
    outline: 0;
}

.wpo-site-header {
    .navigation {
        background-color: $white;
        margin-bottom: 0;
        padding: 0;
        border: 0;
        @include rounded-border(0);

        > .container {
            position: relative;
        }

        @include media-query(991px) {
            padding: 5px 0;
        }
    }

    #navbar {
        @include transition-time(0.5s);

        > ul li a:hover,
        > ul li a:focus {
            text-decoration: none;
            color: darken($theme-primary-color, 5%);
        }

        // style for widther screen
        @include widther(992px) {
            li {
                position: relative;
            }

            > ul > li > a {
                font-size: 16px;
                padding: 35px 20px;
                display: block;
                color: #232f4b;
                font-weight: 700;
                position: relative;

                @media (max-width: 1870px) {
                    padding: 35px 18px;
                }
            }

            > ul .sub-menu {
                background-color: #fff;
                box-shadow: 0px 2px 20px 0px rgba(62, 65, 159, 0.09);
                width: 225px;
                position: absolute;
                padding: 20px 7px;
                left: 0;
                top: 110%;
                z-index: 10;
                visibility: hidden;
                opacity: 0;
                @include transition-time(0.3s);
            }

            > ul > li .sub-menu a {
                font-size: 16px;
                font-size: calc-rem-value(16);
                display: block;
                padding: 10px 15px;
                color: $dark-gray;
                position: relative;
                overflow: hidden;
                font-weight: 700;

                &:after {
                    position: absolute;
                    left: 15px;
                    bottom: 4px;
                    width: 0px;
                    height: 2px;
                    content: '';
                    background: $theme-primary-color;
                    transition: all 0.3s;
                    opacity: 0;
                    visibility: hidden;
                }

                &:hover,
                &.active {
                    &:after {
                        width: 40px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            > ul > li > .sub-menu .sub-menu {
                left: 110%;
                top: 0;
            }

            > ul > li > .sub-menu > .menu-item-has-children > a {
                position: relative;

                &:before {
                    font-family: 'themify';
                    content: '\e649';
                    font-size: 11px;
                    font-size: calc-rem-value(11);
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    @include translatingY();
                }
            }

            /*** hover effect ***/
            > ul > li:hover > .sub-menu {
                top: 100%;
                visibility: visible;
                opacity: 1;
            }

            .sub-menu > li:hover > .sub-menu {
                left: 100%;
                visibility: visible;
                opacity: 1;
            }
        }

        @include media-query(991px) {
            > ul > li a {
                display: block;
                font-size: 15px;
                font-size: calc-rem-value(15);
                color: $white;
                font-weight: 500;

                &:hover,
                &.active {
                    color: $white;
                }
            }

            > ul .sub-menu > li:last-child {
                border-bottom: 0;
            }

            > ul > li > .sub-menu a {
                padding: 8px 15px 8px 45px;
            }

            > ul > li > .sub-menu .sub-menu a {
                padding: 8px 15px 8px 65px;
            }

            > ul .menu-item-has-children > a {
                position: relative;
                color: #ddd;

                &:hover,
                &.active {
                    color: #fff;
                }

                &:before {
                    font-family: 'themify';
                    content: '\e649';
                    font-size: 11px;
                    font-size: calc-rem-value(11);
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    @include translatingY();
                    @include transition-time(0.3s);
                }

                &.rotate {
                    &:before {
                        transform: rotate(90deg);
                        top: 29%;
                    }
                }
                &.rotate {
                    color: #fff;
                }
            }
        }
    }

    /* navigation open and close btn hide for width screen */
    @include widther(992px) {
        .navbar-header .open-btn {
            display: none;
        }

        #navbar .close-navbar {
            display: none;
        }
    }

    .menu-close {
        display: none;
    }

    /* style for navigation less than 992px */
    @include media-query(991px) {
        .container {
            width: 100%;
        }

        .mobail-menu button {
            background-color: $theme-primary-color;
            width: 40px;
            height: 35px;
            border: 0;
            padding: 5px 10px;
            outline: 0;
            position: relative;
            z-index: 20;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            span {
                background-color: $white;
                width: 20px;
                display: block;
                height: 2px;
                margin-bottom: 5px;

                &:last-child {
                    margin: 0;
                }
            }
        }

        #navbar {
            background: #1d3e54;
            display: block !important;
            width: 300px;
            height: 100%;
            margin: 0;
            padding: 0;
            border: 0;
            position: fixed;
            left: -320px;
            top: 0px;
            z-index: 999999;
            opacity: 0;
            visibility: hidden;

            .navbar-nav {
                display: block;
            }

            > ul {
                position: relative;
                z-index: 101;
            }

            ul a {
                color: $black;
                font-weight: 500;
            }

            ul a:hover,
            ul li.current a {
                color: $theme-primary-color;
            }

            .navbar-nav {
                height: 100%;
                overflow: auto;
                padding-bottom: 60px;
            }

            .close-navbar {
                background-color: $white;
                width: 40px;
                height: 40px;
                color: $black;
                border: 0;
                outline: none;
                position: absolute;
                left: -41px;
                top: 90px;
                z-index: 20;

                .ti-close {
                    position: relative;
                    top: 1px;
                }
            }
        }

        .menu-close {
            display: block;
            z-index: 99;
            background: none;
            text-align: center;
            color: $white;
            border: 0;
            text-align: center;
            margin-left: auto;
            margin-top: 30px;
            margin-right: 30px;
            margin-bottom: 30px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border: 1px solid $white;
        }
        /* class for show hide navigation */
        .slideInn {
            left: 0 !important;
            opacity: 1 !important;
            visibility: visible !important;
        }

        .navbar-toggler .first-angle,
        .navbar-toggler .last-angle {
            @include transition-time(0.3s);
        }

        .x-close .middle-angle {
            opacity: 0;
        }

        .x-close .first-angle {
            position: absolute;
            -webkit-transform: rotate(-44deg);
            top: 16px;
            left: 10px;
        }

        .x-close .last-angle {
            -webkit-transform: rotate(44deg);
            position: absolute;
            top: 16px;
            right: 10px;
        }
    }

    @include media-query(767px) {
        .navbar-header .navbar-brand {
            font-size: 24px;
        }

        #navbar .navbar-nav {
            margin: 0;
        }
    }

    /*navbar collaps less then 992px*/
    @include media-query(991px) {
        .navbar-collapse.collapse {
            display: none;
        }

        .navbar-collapse.collapse.in {
            display: block;
        }

        .navbar-header .collapse,
        .navbar-toggle {
            display: block;
        }

        .navbar-header {
            float: none;
        }

        .navbar-right {
            float: none;
        }

        .navbar-nav {
            float: none;
        }

        .navbar-nav > li {
            float: none;
        }
    }
}

@media (max-width: 440px) {
    .wpo-site-header .navbar-header .navbar-brand {
        img {
            max-width: 170px;
        }
    }
}

@media (max-width: 370px) {
    .wpo-site-header .navbar-header .navbar-brand {
        img {
            max-width: 140px;
        }
    }
}

@media (max-width: 1700px) {
    .wpo-site-header {
        padding: 0 30px;
    }
}

@media (max-width: 1500px) {
    .wpo-site-header #navbar > ul > li > a {
        padding: 30px 15px;
    }

    .wpo-site-header {
        padding: 0 10px;
    }
}

@media (max-width: 1400px) {
    .wpo-site-header #navbar > ul > li > a {
        padding: 30px 10px;
        font-size: 16px;
    }
}

@media (max-width: 1200px) {
    .wpo-site-header #navbar > ul > li > a {
        padding: 30px 6px;
        font-size: 15px;
    }

    .wpo-site-header .header-right .theme-btn {
        display: none;
    }
}

@media (max-width: 991px) {
    .wpo-site-header #navbar > ul > li > a {
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .header-right .theme-btn {
        padding: 20px 12px;
    }

    .wpo-site-header .header-right .close-form {
        display: none;
    }
}

// wpo-header-style-1

.wpo-header-style-1 {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 99;
    background: transparentize($dark-gray2, 0.9);

    .navigation {
        background: transparent;

        #navbar > ul > li > a {
            color: $white;

            &:hover,
            &.active {
                color: $theme-primary-color;
            }
        }
    }

    .navigation {
        &.sticky-on {
            background: #1a384c;
        }
    }
}

// wpo-header-style-2

.wpo-header-style-2,
.wpo-header-style-4,
.wpo-header-style-5 {
    .topbar {
        background: $white;

        ul {
            li {
                color: #5d5d5d;

                .fi:before {
                    color: #5d5d5d;
                }
            }
        }
        .contact-info ul li a {
            color: #5d5d5d;

            &:hover {
                color: $theme-primary-color;
            }
        }

        .contact-info ul.social li a {
            border-color: #bdbdbd;

            &:hover {
                color: $white;
                border-color: $theme-primary-color;
            }
        }
    }
    .wpo-site-header {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 99;
        background: transparentize($dark-gray2, 0.9);

        .navigation {
            background: transparent;

            &.sticky-on {
                background: #1a384c;
            }
        }

        #navbar > ul > li > a {
            color: $white;
        }

        .header-right .cart-toggle-btn,
        .header-right .search-toggle-btn {
            background-color: rgba(255, 255, 255, 0.1);
            color: $white;

            .fi {
                color: $white;
            }
        }
    }
}

@media (max-width: 991px) {
    .wpo-site-header #navbar > ul > li > a {
        padding: 15px 30px;
    }
}

@media (max-width: 991px) {
    .wpo-site-header,
    .wpo-header-style-2 {
        z-index: 99999;
    }
}

.wpo-site-header .navigation.sticky-header {
    padding: 0 130px;
}

@media (max-width: 1700px) {
    .wpo-site-header .navigation.sticky-header {
        padding: 0 50px;
    }
}
@media (max-width: 1400px) {
    .wpo-site-header .navigation.sticky-header {
        padding: 0 20px;
    }
}
@media (max-width: 991px) {
    .wpo-site-header .navigation.sticky-header {
        padding: 20px;
    }
}
@media (max-width: 575px) {
    .wpo-site-header .navigation.sticky-header {
        padding: 20px 10px;
    }
}

// .wpo-header3

.wpo-header-style-3 {
    .topbar {
        position: relative;
        z-index: 1;
        overflow: hidden;
        padding-top: 5px;
        padding-bottom: 5px;

        @media (max-width: 991px) {
            padding-bottom: 10px;
        }

        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 70%;
            height: 100%;
            content: '';
            background: $theme-primary-color;
            z-index: -1;

            @media (max-width: 991px) {
                display: none;
            }
        }
        &:before {
            position: absolute;
            right: 26%;
            top: -25px;
            height: 0px;
            width: 0px;
            border-top: 45px solid transparent;
            border-bottom: 45px solid $theme-primary-color;
            border-left: 45px solid $theme-primary-color;
            border-right: 45px solid transparent;
            content: '';

            @media (max-width: 1600px) {
                right: 25%;
            }
            @media (max-width: 1400px) {
                right: 24%;
            }
            @media (max-width: 1200px) {
                right: 23%;
            }
            @media (max-width: 991px) {
                display: none;
            }
        }

        .theme-btn {
            padding: 10px 25px;
            padding-right: 55px;
        }

        ul li {
            font-size: 15px;
            padding: 8px 10px;

            @media (max-width: 1400px) {
                font-size: 14px;
                padding: 8px 0px;
            }
            & + li {
                padding-left: 40px;
                margin-left: 20px;
                position: relative;

                @media (max-width: 767px) {
                    padding-left: 0;
                    margin-left: 10px;
                }

                &::after {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    content: '//';
                    font-size: 25px;

                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }

            .fi {
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: rgba(255, 255, 255, 0.2);
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                margin-right: 10px;

                &:before {
                    padding-right: 0;
                }
            }
        }

        .contact-intro {
            ul {
                @media (max-width: 650px) {
                    flex-wrap: wrap;
                }
            }
        }
    }

    .wpo-site-header {
        position: relative;
        background: $white;
        .header-right {
            .cart-toggle-btn,
            .search-toggle-btn {
                background-color: rgba(0, 0, 0, 0.05);
                .fi:before {
                    color: $text-color;
                    font-size: 18px;
                }
            }
        }
    }
}

// wpo-header-style-4

.wpo-header-style-4 {
    .wpo-site-header {
        position: relative;
        background: $white;
        border-top: 1px solid #e9e9e9;

        #navbar > ul > li > a {
            color: $dark-gray;
        }

        .header-right .search-toggle-btn {
            background-color: rgba(0, 0, 0, 0.05);
            .fi {
                &:before {
                    color: $dark-gray;
                }
            }
        }

        .navigation.sticky-on {
            background: $white;
        }
    }
}

// .wpo-header-style-5

.wpo-header-style-5 {
    .wpo-site-header {
        border-bottom: 1px solid transparentize($white, 0.9);
        background: transparent;

        #navbar > ul > li > a {
            &:hover,
            &.active {
                background: transparentize($white, 0.9);
            }

            &:before {
                display: none;
            }
        }
    }
    .topbar {
        background: $theme-primary-color;

        ul li {
            color: $white;

            .fi:before {
                color: $white;
            }
        }

        .contact-info ul li a {
            color: $white;

            &:hover {
                color: $white;
            }
        }

        .contact-info ul.social li a {
            border-color: $white;

            &:hover {
                background-color: $white;
                color: $theme-primary-color;
            }
        }
    }
}

// wpo-header-style-6

.wpo-header-style-6,
.wpo-header-style-7 {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 999;

    .wpo-site-header {
        max-width: 90%;
        margin: 0 auto;
        padding: 0;
        padding-left: 10px;
        border-radius: 5px;

        @media (max-width: 450px) {
            padding-left: 0;
            padding-right: 5px;
        }

        .navigation {
            border-radius: 5px;
        }

        .header-right {
            .search-toggle-btn {
                background-color: rgba(0, 0, 0, 0.05);
                .fi {
                    &:before {
                        color: $dark-gray;
                    }
                }
            }

            .close-form {
                .theme-btn {
                    background: $dark-gray2;
                    padding: 12px 25px;
                    &:before {
                        display: none;
                    }
                    @media (max-width: 1500px) {
                        padding: 10px 18px;
                    }
                    @media (max-width: 1400px) {
                        padding: 10px;
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .topbar {
        background: none;
        text-align: center;
        padding-top: 20px;

        p {
            color: $dark-gray2;
            font-size: 17px;
            font-weight: 500;

            @media (max-width: 400px) {
                font-size: 16px;
            }

            span {
                color: $theme-primary-color;
            }
        }
    }
}

// wpo-header-style-7

.wpo-header-style-7 {
    .topbar {
        p {
            color: $white;
        }
    }
}

.wpo-header-style-7 {
    &.topbar-none {
        margin-top: 65px;
        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }
}

// wpo-header-style-6

.wpo-header-style-6 {
    .topbar {
        p {
            color: $dark-gray2;
        }
    }
}

.wpo-site-header .header-right button.btn {
    background: #81929d;
    height: 20px;
    line-height: 24px;
    position: relative;
    right: 6px;
    width: 20px;
}

// sticky header css

.fixed-navbar {
    position: relative;
    z-index: 99999;
}
.fixed-navbar.active .wpo-site-header,
.fixed-navbar.active .header-style-2 {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    background: $white;
    border: none;
    border-radius: 0;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.fixed-navbar.active .wpo-header-style-1,
.fixed-navbar.active .wpo-header-style-5 .wpo-site-header {
    background: #1a384c;
    max-width: 100%;
}

.Toastify {
    position: relative;
    z-index: 99999;
}

.wpo-header-style-1 {
    .shop-header {
        background: rgba(20, 33, 43, 0.6);

        .header-right .search-toggle-btn,
        .header-right .cart-toggle-btn {
            background: $white;
        }
    }
}

.fixed-navbar.active .wpo-header-style-1 .shop-header {
    background: #1a384c;
}

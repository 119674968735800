/* Add these styles to your CSS or SCSS file */
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

.projectwhole{
 padding-top: 50px;
 padding-bottom: 50px;
 /* font-family: "Mulish", sans-serif; */
 font-family: "PT Serif", serif;
}

.project-description {
    padding: 20px;
    padding-top: 50px;
    /* background-color: #f7f7f7; */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color:#000;
}

.wpo-project-single-title {
    color: #18455D;
    font-size: 20px;
    
}

.project-description p {
    color:#000;
    font-size:20px;
    text-align: justify;
}

.project-details .project-details-list{
    padding-top: 70px;
    padding-left: 50px;
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    font-weight: 500;
}

.project-details-list {
    list-style-type: none;
    padding: 0;
}

.project-details-list li {
    margin-bottom: 10px;
    font-size: 24px;
    color: #555;
    font-weight: 700;
    color: #18455D;
}

.project-details-list li span {
    font-weight: bold;
    font-size: 20px;
    color: #333; /* Adjust the color to your preference */
}

.with-border {
    border-right: 2px solid #FF4A17; /* Adjust the width and color as needed */
    padding-right: 10px; /* Optional: Add some padding to visually separate the border from content */
  }

  .project-details-list  .go-to-website-button{
    margin-top: 20px;
}

  .go-to-website-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #FF4A17;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-radius: 5px;
}
.go-to-website-button:hover{
    display: inline-block;
    padding: 10px 20px;
    background-color: #FF4A17;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}
/* Customize styles as per your design preferences */
@media (max-width: 480px) {
    .project-details .project-details-list{
        padding-left: 0px;
    }
}

/* BlogSection.css */
.wpo-blog-wrap {
    display: flex;
    flex-wrap: wrap;
}


@media (max-width: 1024px) {
    .wpo-blog-wrap .col-lg-4 {
        width: 33.33%; /* Ensure three columns for tablet view */
    }
}

@media (max-width: 767px) {
    .wpo-blog-wrap .col-md-6 {
        width: 100%; /* Single column for smaller screens */
    }
}

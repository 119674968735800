/* ProjectSection.css */
.slick-prev, .slick-next {
    top: calc(50% - 70px); /* Adjust this value to vertically center the arrows */
}

.slick-prev {
    left: -35px; /* Adjust as needed */
}

.slick-next {
    right: -35px; /* Adjust as needed */
}

.slick-slider {
    position: relative;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

/* .slick-track {
    display: flex;
    align-items: center; /* Center align the items 
   
} 
*/

/* Tablet view adjustments */
@media (max-width: 1200px) {
    .wpo-project-section .wpo-project-container .slick-arrow.slick-prev {
        left: 0px !important; /* Adjust as needed for tablet view */
    }

    
}
.wpo-blog-all-pages {
    .wpo-div-section {
        text-align: justify;

    }
    p {
        font-family: "Roboto", sans-serif;
        text-align: justify;
        font-weight: 400;
    }

    h2 {
         font-family: "PT Serif", serif;
         text-align: left !important
        ;
    }

    ul{
        list-style: none;
        margin-bottom: 30px;
        font-size: 16px;
       font-weight: 400;

        li{
            padding: 10px 0;
            padding-left: 35px;
            position: relative;

            &:before{
               position: absolute;
               left: 0;
               top: 50%;
               transform: translateY(-50%);
               width: 25px;
               height: 25px;
               line-height: 25px;
               border-radius: 50%;
               background: transparentize(#ff4a17, .9);
               content: "\f178";
               font-family: "FontAwesome";
               text-align: center;
               color: #ff4a17;
               font-size: 13px;
            }
        }
    }

}
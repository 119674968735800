/*Portfolio Image Scrolling Style*/
/* box container */
.website-box {
 overflow: hidden;  
    /*position: relative;  */
    height: 500px; /* Set the initial height for the image container */
    transition: height 10s ease; /* Add a smooth transition for height changes */
}

.website-img {
    width: 100%; /* Ensure the image takes up the full width of its container */
    height: auto; /* Maintain the aspect ratio of the image */
    transition: transform 10s ease; /* Add a smooth transition for transform changes */
}

/* Adjust the transform property to show the entire image on hover */
.website-box:hover .website-img {
    transform: translateY(-95%);
}

/* Media query for smaller screens, adjust the height as needed */
@media (max-width: 768px) {
    .website-box {
        height: 200px; /* Adjust the height for smaller screens */
    }

    .website-box:hover .website-img {
        transform: translateY(-90%);
    }
}

@media (max-width: 480px) {
    .website-box {
        height: 200px; /* Adjust the height for smaller screens */
    }

    .website-box:hover .website-img {
        transform: translateY(-90%);
    }
}
/* Add this CSS to your stylesheets */
.mobile-project-overlay {
    position: relative;
}

.mobile-project-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px; /* Adjust as needed */
    background-color: #FF4A17; /* Your desired background color */
    color: white; /* Your desired text color */
    text-align: center;
    margin: 0;
    opacity: 0; /* Initially hide the titles */
    transition: opacity 0.3s ease; /* Add a smooth transition effect */
}

.my-container {
    display: flex;
    flex-wrap: wrap;
  }
  
 .my-grid {
    width: 33.33%; /* Set each item to take up 1/3 of the container width */
    box-sizing: border-box;
    padding: 10px; /* Adjust the padding as needed */
  }
  



.mobile-project-overlay:hover .mobile-project-title {
    opacity: 1; /* Make titles visible on hover */
}

.wpo-project-item {
    margin-bottom: 50px; 
} 


.button-container {
    text-align: center;
  }
  
  .load-more-button {
    display: inline-block;
    padding: 10px 30px;
    background-color: #FF4A17;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
  }
  
  /* .wpo-project-section .wpo-project-container .grid .wpo-project-text{
    top: -46px;
  } */

  /* General styles for filter buttons */
/* .filter-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  margin-bottom: 50px; 
}

.filter-buttons button {
  background-color: #FF4A17; 
  color: #fff;
  border: none; 
  padding: 10px 20px; 
  margin: 5px; 
  border-radius: 5px;
  font-size: 16px; 
  cursor: pointer; 
  transition: background-color 0.3s, transform 0.3s; 
}

.filter-buttons button:hover {
  background-color: #FF4A17; 
  transform: scale(1.05); 
}


@media (max-width: 767px) {
  .filter-buttons {
      flex-direction: column; 
      align-items: center;
  }

  .filter-buttons button {
      width: 100%; 
      max-width: 300px; 
      text-align: center;
  }
} */

/* General styles for filter buttons */
.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center buttons horizontally */
  margin-bottom: 50px; /* Space below the buttons */
}

/* Default button styling */
.filter-buttons button {
  background-color: transparent; /* Default background color for unselected buttons */
  color: #FF4A17; /* Orange text color for unselected buttons */
  border: 2px solid #FF4A17; /* Orange border for unselected buttons */
  padding: 10px 20px; /* Padding around the text */
  margin: 5px; /* Space between buttons */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, transform 0.3s; /* Smooth transitions */
}

/* Style for the selected button */
.filter-buttons .selected {
  background-color: #FF4A17; /* Orange background for selected button */
  color: #fff; /* White text color for selected button */
  border: none; /* Remove border for selected button */
}

/* Hover effect */
.filter-buttons button:hover {
  background-color: rgba(255, 74, 23, 0.8); /* Slightly transparent orange background on hover */
  color: #fff;
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .filter-buttons {
    display: grid; /* Use grid layout for buttons */
    grid-template-columns: repeat(3, 1fr); /* 3 buttons per row */
    grid-gap: 5px; /* Space between buttons */
    overflow-x: hidden; /* Hide horizontal overflow */
  }

  /* Add a media query for smaller screens or specific breakpoints to handle 2 buttons per row */


  .filter-buttons button {
    font-size: 12px; /* Reduce font size */
    padding: 8px 8px; 
    margin: 3px; /* Remove margin to fit buttons better */
    white-space: nowrap; /* Prevent text from wrapping */
  }
}


.slick-prev, .slick-next {
    z-index: 1; /* Ensure arrows are above other content */
}

.slick-prev {
    left: 10px; 
    margin-top:50px;
}

.slick-next {
    right: 10px; 
    margin-top:50px;
}

@media (max-width: 600px) {
    .slick-prev {
        left: 5px; /* Adjust as needed */
    }
    
    .slick-next {
        right: 5px; /* Adjust as needed */
    }
    .slick-prev, .slick-next {
        font-size: 20px; /* Increase size if necessary */
        width: 30px; /* Adjust width */
        height: 30px; /* Adjust height */
        margin-top:100px;
    }
}

.accordion-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: "PT Serif", serif;
  }

  .accordion-column {
    width: 48%;
  }
  
  .accordion-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .accordion-title {
    cursor: pointer;
    padding: 10px;
    background-color: #1A384C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:white;
    font-weight: bold;
    font-size: 18px;
  }

  .accordion-title.active,
  .accordion-title:active {
      background-color: #FF4A17; /* Change to the desired color when clicked */
  }
  

  
  
  /* .accordion-item.open .accordion-icon {
    transform: rotate(180deg);
  } */
  

.accordion-title {
  cursor: pointer;
  padding: 14px 30px;
  background-color: #1A384C;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.accordion-title.active,
.accordion-title:active {
  background-color: #FF4A17; /* Color when clicked or content is shown */
}

.accordion-icon {
  /* font-size: 20px;
  transition: transform 0.2s; */
}

/* .accordion-title.active .accordion-icon {
  transform: rotate(180deg); 
} 
*/

.accordion-content {
  padding: 30px;
  /* padding-top: 30px;
  padding-bottom: 30px; */
  background-color: #fff;
  color:#000;
  font-family: "PT Serif", serif;
  font-size: 15px;
  font-weight: bold;
}

.contact-validation-active {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 20px; /* Adjust padding as needed */
    border-radius: 8px; /* Adjust border-radius as needed */
}

.contact-heading{
    text-align: center;
    padding: 20px;
}

.form input,
.form textarea,
.form select {
  border: 2px solid #dcdcdc !important;
  border-radius: 0;
  outline: 0;
  box-shadow: none;
  color: #595959;
  font-style: normal;
}
.form input:focus,
.form textarea:focus,
.form select:focus {
  border-color: #adadc9 !important;
  -o-box-shadow: 0 0 5px  #adadc9 !important;
  -ms-box-shadow: 0 0 5px  #adadc9 !important;
  box-shadow: 0 0 5px  #adadc9 !important;
}
.form ::-webkit-input-placeholder {
  font-style: 14px;
  font-style: italic;
  color: #595959;
}